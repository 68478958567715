import request from "@/utils/request";

// 获取首页banner图
export function queryIndexBanner(id, query) {
  return request({
    url: "/home/banner",
    method: "get",
    params: query,
  });
}
// 获取导航菜单
export function queryCommonMenu(query) {
  return request({
    url: "/common/menu",
    method: "get",
  });
}
// 获取内容参数
export function queryCommonParam(query) {
  return request({
    url: "/common/param",
    method: "get",
  });
}
// 获取首页产品中心
export function queryIndexProduct() {
  return request({
    url: "/home/product",
    method: "get",
  });
}
// 获取首页证书
export function queryIndexCertificate() {
  return request({
    url: "/home/cert",
    method: "get",
  });
}
// 获取首页文章
export function queryIndexArticle() {
  return request({
    url: "/home/article",
    method: "get",
  });
}
// 获取证书授权列表
export function queryCetificate() {
  return request({
    url: "/article/list",
    method: "get",
  });
}

// 分页获取新闻资讯列表
export function queryArticleList(query) {
  return request({
    url: "/article/list",
    method: "post",
    data: query,
  });
}
// 获取资讯详情
export function queryArticleInfo(id) {
  return request({
    url: "/article/" + id,
    method: "get",
  });
}


