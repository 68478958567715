import axios from 'axios'
import router from "../router";
import { Notification, MessageBox, Message } from "element-ui";
import { getToken } from '@/utils/auth'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000
})
// request拦截器
service.interceptors.request.use(
  config => {
      config.headers["language"] = sessionStorage.getItem("languageSet"); 
    return config
  },
  error => {
    console.log(error)
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(res => {
    const code = res.data.code
    if (code === 401) {
      MessageBox.confirm(
        '请重新登录',
        'tips',
        {
          confirmButtonText: '登录',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        router.push({
          path:'/login'
        })
      })
    } else if (code !== 200 && code !== 1000 && code !== 203) {
      Notification.error({
        title: res.data.msg
      })
      return Promise.reject('error')
    } else if (code == 203) {
      // store.dispatch('LogOut').then(() => {
      //   location.reload() // 为了重新实例化vue-router对象 避免bug
      // })
      console.log(router)
      router.push({
        path:'/login'
      })
    } else {
      return res.data
    }
  },
  error => {
    console.log('err' + error)
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
