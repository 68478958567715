module.exports = {
  language: {
    name: "English",
  },
  //   navList: [
  //     { name: "Home", route: "/index" },
  //     { name: "商城", route: "/mall" },
  //     { name: "产品中心", route: "/product" },
  //     { name: "产品应用", route: "/application" },
  //     { name: "公司简介", route: "/intro" },
  //     { name: "证书授权", route: "/application" },
  //   ],
  footer: {
    quick: {
      name: "Quick Link",
      list: [
        { name: "Mall", route: "https://www.weidiansi.com/" },
        { name: "Product Center", route: "/product" },
        { name: "COMPANY PROFILE", route: "/aboutUs" },
        { name: "NEWS", route: "/news" },
        { name: "Contect Us", route: "/contact" },
      ],
    },
    contact: {
      name: "Contect Us",
      list: ["Service QQ：", "Service：", "Telphone", "Address：",'Mall：'],
    },
    focus: {
      name: "Focus Us",
    },
    copyright: {
      name:
        "Copyright 2008-2020 weidiansi.com,Inc.All right reserved. Service hotline：4008-933-885 粤ICP备19100740号",
    },
  },
  index: {
    slogan: [
      { name: "Genuine Product Guarantee" },
      { name: "Good Aftermarket" },
      { name: "One-stop Shopping" },
      { name: "Mark The Price Clearly" },
    ],
  },
  product: {
    cname: "产品中心",
    sname: "PRODUCT",
    lookText: "Details",
    more: "More",
  },
  application: {
    cname: "产品应用",
    sname: "Product application",
  },
  introduce: {
    cname: "公司介绍",
    sname: "COMPANY PROFILE",
    information:
      "Shenzhen d electric technology co., LTD. Is located in shenzhen baoan district xixiang air city avenue street pond dongguang electric r&d building on the second floor",
    list: [
      { name: "Mission of company", info: "For China, for a good core" },
      {
        name: "The company vision",
        info: 'Become "the most trusted electronic service platform',
      },
      {
        name: "Company values",
        info:
          'Integrity, because we have a foothold in business "integrity" of the win-win, due to the "win-win", we can walk far, innovation, "innovation" we were able to sustainable development',
      },
    ],
  },
  certificate: {
    cname: "证书授权",
    sname: "Certificate",
  },
  news: {
    cname: "新闻资讯",
    sname: "NEWS",
    lookText: "Check the details",
    back: "Go Back",
    publish: "Delivery time：",
    readnum: "Viewed times：",
    // origin: "来源：",
    // author: "作者：",
    // last: "上一篇：",
    // next: "下一篇：",
  },
  job: {
    duty: "Job responsibilities",
    request: "Job requirements",
    search: "Search jobs",
  },
  contact: {
    cname: "联系我们",
    sname: "Contact Us",
  },
  aboutUs: {
    cname: "公司简介",
    sname: "company profile",
  },
  weidiansiInfo: {
    company: "深圳市维电斯科技有限公司",
    companyEn: "Shenzhen Weidiansi Technology Co., Ltd",
  },
};
