
const index = {
  state: {
    anchorIndex: "",
    language: "zh",
    footerShow: true,
    imgUploadUrl: "https://adminwds.weidiansi.cn/prod-api",
    headerSearchValue: "",
    headerSearchTime: "",
    companyInfo: {
      // dbdh:"顶部电话"
      // gsjs:"公司介绍"
      // kfqq:"客服QQ"
      // kfdh:"客服电话"
      // lxsj:"联系手机"
      // dbdz:"底部地址"
      // wxgzh:"微信公众号"
    },
  },
  mutations: {
    SET_ANCHORINDEX(state, index) {
      state.anchorIndex = index;
    },
    SET_LANGUAGE(state, language) {
      state.language = language;
    },
    SET_COMPANY_INFO(state, companyInfo) {
      state.companyInfo = companyInfo;
    },
    SET_FOOTER_SHOW(state, show) {
      state.footerShow = show;
    },
    SET_HEADER_SEARCH_VALUE(state, value) {
      state.headerSearchValue = value;
    },
    SET_HEADER_SEARCH_TIME(state, value) {
      state.headerSearchTime = value;
    },
  },
};
export default index