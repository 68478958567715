
import Vue from "vue";
import { getToken, setToken, removeToken } from '@/utils/auth'
const user = {
  state: {
    userInfo: {},
    roles: [],
    token:'',
    token_type: '',
    refresh_token:'',
  },
  mutations: {
    SET_TOKEN (state, token) {
      state.token = token
    },
    SET_TOKEN_TYPE (state, token_type) {
      state.token_type = token_type
    },
    SET_REFRESH_TOKEN (state, refresh_token) {
      state.refresh_token = refresh_token
    },
    SET_USERINFO (state, info) {
      if (typeof info !== 'object') throw new Error('basicInfo type invalid')
      state.userInfo = info
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    }
  },
  actions:{
    //获取用户信息
    // GetUserInfo({ commit, state }) {
    //   return new Promise((resolve,reject) => {
    //    login.getUserInfo().then( res => {
    //       if (res.data.data.roles && res.data.data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
    //         commit('SET_ROLES', res.data.data.roles)
    //         commit('SET_PERMISSIONS', res.data.data.permissions)
    //       } else {
    //         commit('SET_ROLES', [res.data.data.roleType])
    //       }
    //       commit('SET_USERINFO', res.data.data)
    //       commit('SET_ROLES', res.data.data.roleType)
    //       resolve(res)
    //     }).catch(err => {
    //       console.log(err+'----->')
    //       reject(err)
    //     })
    //   })
    // },
     // 退出系统
    //  LogOut({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     commit('SET_TOKEN', '')
    //     removeToken()
    //     resolve()
    //   })
    // },

    // 前端 登出
    // FedLogOut({ commit }) {
    //   return new Promise(resolve => {
    //     commit('SET_TOKEN', '')
    //     removeToken()
    //     resolve()
    //   })
    // }
  }
}
export default user