module.exports = {
  language: {
    name: "English",
  },
  // navList: [
  //   { name: "首页", route: "/index" },
  //   { name: "商城", route: "/mall" },
  //   { name: "产品中心", route: "/product" },
  //   { name: "产品应用", route: "/application" },
  //   { name: "公司简介", route: "/intro" },
  //   { name: "证书授权", route: "/application" },
  // ],
  footer: {
    quick: {
      name: "快捷链接",
      list: [
        { name: "商城", route: "https://www.weidiansi.com/"  },
        { name: "产品中心", route: "/product" },
        { name: "公司简介", route: "/aboutUs" },
        { name: "新闻资讯", route: "/news" },
        { name: "联系我们", route: "/contact" },
      ],
    },
    contact: {
      name: "联系我们",
      list: ["客服QQ：", "客服：", "联系手机：", "地址：","商城网址："],
    },
    focus: {
      name: "关注我们",
    },
    copyright: {
      name:
        "Copyright 2008-2020 weidiansi.com,Inc.All right reserved. 服务热线：4008-933-885 粤ICP备19100740号",
    },
  },
  index: {
    slogan: [
      { name: "正品保证" },
      { name: "无忧售后" },
      { name: "一站式采购" },
      { name: "明码标价" },
    ],
  },
  product: {
    cname: "产品中心",
    sname: "PRODUCT",
    lookText: "查看详情",
    more: "更多产品",
  },
  application: {
    cname: "产品应用",
    sname: "Product application",
  },
  introduce: {
    cname: "公司介绍",
    sname: "COMPANY PROFILE",
    information:
      " 深圳市维电斯科技有限公司位于深圳市宝安区西乡街道航城大道塘东光电研发大厦二层",
    list: [
      { name: "公司使命", info: "为中国，供好芯" },
      { name: "公司愿景", info: "成为“最值得信赖”的电子元器件服务平台" },
      {
        name: "公司价值观",
        info:
          "诚信，因“诚信”我们有立足立业之本 共赢，因“共赢”我们可以走很远 创新，因“创新”我们得以永续经营",
      },
    ],
  },
  certificate: {
    cname: "证书授权",
    sname: "Certificate",
  },
  news: {
    cname: "新闻资讯",
    sname: "NEWS",
    lookText: "查看详情",
    back: "返回",
    publish: "发布时间：",
    readnum: "浏览次数：",
    // origin: "来源：",
    // author: "作者：",
    // last: "上一篇：",
    // next: "下一篇：",
  },
  job: {
    duty: "工作职责",
    request: "工作要求",
    search: "搜索岗位",
  },
  contact: {
    cname: "联系我们",
    sname: "Contact Us",
  },
  aboutUs: {
    cname: "公司简介",
    sname: "company profile",
  },
  weidiansiInfo: {
    company: "深圳市维电斯科技有限公司",
    companyEn: "Shenzhen Weidiansi Technology Co., Ltd",
  },
};
