<template>
  <div id="footer">
    <div class="wei-container footer-container">
      <div class="quick footer-item">
        <div class="name">{{ quick.name }}</div>
        <div class="box" v-if="quick.list">
          <div
            class="item pointer"
            v-for="(item, index) in quick.list"
            :key="index"
            @click="toUrl(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="contact footer-item">
        <div class="name">{{ contact.name }}</div>
        <div class="box" v-if="contact.list && companyInfo">
          <div class="item">{{ contact.list[0] }}{{ companyInfo.kfqq }}</div>
          <div class="item">{{ contact.list[1] }}{{ companyInfo.kfdh }}</div>
          <div class="item">{{ contact.list[2] }}{{ companyInfo.lxsj }}</div>
          <div class="item">{{ contact.list[3] }}{{ companyInfo.dbdz }}</div>
        </div>
      </div>
      <div class="focus footer-item">
        <div class="name">{{ focus.name }}</div>
        <div class="qrcode">
            <img :src="imgUploadUrl + companyInfo.wxgzh" alt="" />
        </div>
      </div>
    </div>
    <div class="copyright">{{ copyright.name }}</div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  data() {
    return {
      quick: {},
      contact: {},
      focus: {},
      copyright: {},
    };
  },
  computed: {
    ...mapGetters(["companyInfo",'imgUploadUrl']),
  },
  mounted() {
    this.quick = this.$t("footer.quick");
    this.contact = this.$t("footer.contact");
    this.focus = this.$t("footer.focus");
    this.copyright = this.$t("footer.copyright");
  },
  methods: {
    selectNav(selectIndex, name) {
      this.$store.commit("SET_ANCHORINDEX", selectIndex);
      if (this.$route.name == name) {
        return;
      } else {
        this.$router.push({ name: name });
      }
    },
    toUrl(item) {
      if(item.route.indexOf('http')>-1){
        window.open(item.route); 
      }else{
          this.$router.push(item.route);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#footer {
  background: #19222f;

  .footer-container {
    display: flex;
    padding: 80px 15px;
    .footer-item {
      flex: 1;
      text-align: left;
      background: #19222f;
      .name {
        font-size: 16px;
        color: #ffffff;
        margin-bottom: 28px;
      }
      .box {
        font-size: 12px;
        color: #aaaaaa;
        .item {
          margin-bottom: 19px;
          &.pointer:hover {
            color: #fff;
          }
        }
      }
      &.focus {
        .qrcode {
          img {
            width: 120px;
            height: 122px;
          }
        }
      }
    }
  }
  .copyright {
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #131922;
    font-size: 12px;
    color: #525a66;
  }
}
</style>
