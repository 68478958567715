<template>
  <div id="app">
    <keep-alive >
     <router-view />
    </keep-alive>
  </div>
</template>
<script>

export default {
  // mounted(){
  //     this.$store.commit("SET_LANGUAGE", 'en');
  //     console.log(this.$store.getters.language)
  //     this.$i18n.locale==this.$store.getters.language
  //     console.log(this.$i18n.locale)
  // }
}
</script>
<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: PingFangSC-Regular, PingFang SC;
    font-weight:400;
    background:#F7F8FA;
}

</style>
