const getters = {
  permission_routers: (state) => state.permission.routes,
  addRouters: (state) => state.permission.addRoutes,
  token: (state) => state.user.token,
  userInfo: (state) => state.user.userInfo,
  companyId: (state) => state.user.userInfo.companyId,
  roles: (state) => state.user.roles,
  language: (state) => state.index.language,
  companyInfo: (state) => state.index.companyInfo,
  footerShow: (state) => state.index.footerShow,
  imgUploadUrl: (state) => state.index.imgUploadUrl,
  headerSearchValue: (state) => state.index.headerSearchValue,
  headerSearchTime: (state) => state.index.headerSearchTime,
};
export default getters