<template>
  <div id="header-container" :class="{ imgBg: showImgBg }">
    <div class="header-top wei-container">
      <div class="logo-container">
        <router-link :to="{ name: 'Index' }">
          <div class="logo-container">
             <img
              src="../../assets/images/logo1.png"
              class="logo1"
            />
              <img
              src="../../assets/images/logo2.png"
              class="logo2"
            />
          </div>
        </router-link>
      </div>
      <div class="right-container">
        <el-input
          prefix-icon="el-icon-search"
          clearable=""
          @keyup.enter.native="search"
          v-model="keyword"
        ></el-input>
        <div class="phone">
          <i class="el-icon-phone"></i>
          <span class="telphone">{{ companyInfo.dbdh }}</span>
        </div>
        <span
          class="lan"
          @click="changeLanguage('zh')"
          :class="{ active: locale == 'zh' }"
          >中文</span
        >/
        <span
          class="lan"
          @click="changeLanguage('en')"
          :class="{ active: locale == 'en' }"
          >English</span
        >
      </div>
    </div>
    <nav class="wei-container">
      <div
        class="nav-item pointer"
        v-for="(item, index) in navList"
        :key="index"
        @click="toUrl(item)"
        :class="{ active: activeRoute == item.path }"
      >
        {{ item.name }}
        <img class="hot"  :class="{ 'en_hot': locale == 'en' }" v-if="item.name==='网上商城'||item.name==='Mall'" src="../../assets/images/hot.png" alt="">
      </div>
    </nav>
  </div>
</template>
<script>
import { queryCommonParam, queryCommonMenu } from "@/api/";
import { mapGetters, mapMutations } from "vuex";
import { scrollTo } from "@/utils/scroll-to";
export default {
  name: "Header",
  data() {
    return {
      locale: "zh",
      keyword: "",
      navList: [],
      activeRoute: "/index",
      showImgBg: false,
    };
  },
  created() {
    this.navList = this.$t("navList");
    this.activeRoute = this.$route.path;
    this.matchRoute();
    this.getQueryData();
  },
  watch: {
    "$route.matched"(val) {
      this.activeRoute = val[val.length - 1].path;
      if (this.activeRoute !== "/product") {
        this.keyword = "";
      }
      this.matchRoute();
    },
  },
  computed: {
    ...mapGetters(["companyInfo"]),
  },
  methods: {
    ...mapMutations([
      "SET_COMPANY_INFO",
      "SET_HEADER_SEARCH_VALUE",
      "SET_HEADER_SEARCH_TIME",
    ]),
    matchRoute() {
      if (this.activeRoute === "/index" || this.activeRoute === "/job") {
        this.showImgBg = false;
      } else {
        this.showImgBg = true;
      }
    },
    getQueryData() {
      queryCommonParam().then((res) => {
        this.SET_COMPANY_INFO(res.data);
      });
      queryCommonMenu().then((res) => {
        this.navList = res.data;
      });
    },
    changeLanguage(lan) {
      let locale = this.$i18n.locale;
      if (this.$i18n.locale == lan) return;
      this.$i18n.locale == "zh"
        ? (this.$i18n.locale = "en")
        : (this.$i18n.locale = "zh");
      locale = this.$i18n.locale;
      sessionStorage.setItem("languageSet", this.$i18n.locale);
      location.reload();
    },
    search() {
      if (this.$route.name == "product") {
        this.SET_HEADER_SEARCH_VALUE(this.keyword);
        this.SET_HEADER_SEARCH_TIME(Date.now());
      } else {
        this.$router.push("/product?keyword=" + this.keyword);
      }
    },
    toUrl(item) {
      if(item.path.indexOf('http')>-1){
        window.open(item.path); 
      }else{
          this.$router.push(item.path);
             scrollTo(0, 800);
      }
   
    },
  },
  mounted() {
    this.locale = this.$i18n.locale;
  },
};
</script>
<style lang="scss" scoped>
#header-container {
  width: 100%;
  background: #19222f;
  // min-width:1200px;
  &.imgBg {
    background: url("../../assets/images/banner.jpg") no-repeat;
    background-position: top;
  }
  .header-top {
    text-align: left;
    color: #fff;
    height: 88px;
    .logo-container {
      float: left;
      margin-top:6px;
      .logo1{
           width:44px;
           vertical-align: top;
      }
      .logo2{
        width:70px;
        vertical-align: top;
        margin-top:4px;
          // height: 88px/*  */;
      }
    }
    .right-container {
      float: right;
      line-height: 1;
      height: 88px;
      .phone {
        display: inline-block;
        margin-right: 40px;
        .telphone {
          margin-left: 7px;
        }
      }
      span.lan {
        display: inline-block;
        height: 100%;
        line-height: 1;
        color: #fff;
        cursor: pointer;
        &:hover,
        &.active {
          color: #41cfad;
        }
      }
    }
  }
  nav {
    color: #fff;
    display: flex;
    height: 60px;
    .nav-item {
      flex: 1;
      text-align: center;
      align-self: center;
      font-size: 16px;
      position:relative;
      .hot{
          width:34px;
          position: absolute;
          top:-30px;;
          right:50%;
          margin-right:-49px;
          &.en_hot{
            margin-right: -30px;
          }
      }
      &.active,
      &:hover {
        color: #41cfad;
      }
    }
  }
}
</style>
<style lang="scss">
.header-top {
  .el-input {
    display: inline-block;
    width: 120px;
    height: 24px;
    line-height: 1;
    border: 1px solid #ffffff;
    border-radius: 12px;
    margin-top: 32px;
    margin-right: 40px;
    .el-input__inner {
      height: 100%;
      line-height: 1;
      background: transparent;
      border: 0;
      padding: 0 22px;
      color: #fff;
    }
    .el-input__icon {
      line-height: 1;
      width: 14px;
      height: 14px;
      margin-top: 5px;
    }
  }
}
</style>
