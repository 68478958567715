<template>
  <div id="layout">
    <head-nav class="header"></head-nav>
    <router-view />
    <foot v-show="footerShow"></foot>
  </div>
</template>
<script>
import headNav from "@/components/Header/Index";
import foot from "@/components/Footer/Index";
import { mapGetters } from "vuex";
export default {
  name: "Layout",
  components: {
    headNav,
    foot,
  },
   computed: {
    ...mapGetters(["footerShow"]),
  },
};
</script>
<style lang="scss" scoped>
#layout {
  width: 100%;
  // min-width: 1200px;
  height: 100%;
  padding-top: 148px;
  .header{
     position: fixed;
  top:0;
  z-index: 10000000;
  }
  .el-main {
    width: 100%;
    min-height: 100%;
    height: auto;
  }
}
</style>
