import Vue from "vue";
import Router from "vue-router";
import Layout from "../views/layout/index.vue";
Vue.use(Router);

export const constantRouterMap = [
         {
           path: "",
           hidden: true,
           redirect: "/index",
         },
         {
           //首页
           path: "/index",
           name: "Index",
           component: Layout,
           meta: { title: "首页", requiresAuth: true, enTitle: "Home" },
           children: [
             {
               path: "/index",
               name: "Index",
               meta: {
                 title: "深圳市维电斯科技有限公司",
                 icon: "",
                 enTitle: "Home",
                 description:
                   "深圳市维电斯科技有限公司主要经营范围是电子元器件的销售,五金产品,电子产品销售,电子软件开发,国内贸易、货物及技术进出口",
                 keywords:
                   "维电斯,维电斯科技,元器件,电子元器件,保险丝保险管,放电管防雷管,压敏电阻,热敏电阻,集成电路,电感,电阻,电容,静电保护器",
               },
               component: () => import("../views/index/index.vue"),
             },
             {
               path: "/author",
               name: "Author",
               meta: {
                 title: "证书授权-深圳市维电斯科技有限公司",
                 icon: "",
                 enTitle: "Author",
                 description:
                   "深圳市维电斯科技有限公司主要经营范围是电子元器件的销售,五金产品,电子产品销售,电子软件开发,国内贸易、货物及技术进出口",
                 keywords: "证书授权,深圳市维电斯科技有限公司",
               },
               component: () => import("../views/authority/index.vue"),
             },
             {
               path: "/contact",
               name: "Contact",
               meta: {
                 title: "联系我们-深圳市维电斯科技有限公司",
                 icon: "",
                 enTitle: "Contact us",
                 description:
                   "深圳市维电斯科技有限公司主要经营范围是电子元器件的销售,五金产品,电子产品销售,电子软件开发,国内贸易、货物及技术进出口",
                 keywords: "联系我们,深圳市维电斯科技有限公司",
               },
               component: () => import("../views/contact/index.vue"),
             },
             {
               path: "/aboutUs",
               name: "aboutUs",
               meta: {
                 title: "公司简介-深圳市维电斯科技有限公司",
                 icon: "",
                 enTitle: "About Us",
                 description:
                   "深圳市维电斯科技有限公司主要经营范围是电子元器件的销售,五金产品,电子产品销售,电子软件开发,国内贸易、货物及技术进出口",
                 keywords: "公司简介,深圳市维电斯科技有限公司",
               },
               component: () => import("../views/aboutUs/index.vue"),
             },
             {
               path: "/job",
               name: "Job",
               meta: {
                 title: "人才招聘-深圳市维电斯科技有限公司",
                 icon: "",
                 enTitle: "Job",
                 description:
                   "深圳市维电斯科技有限公司主要经营范围是电子元器件的销售,五金产品,电子产品销售,电子软件开发,国内贸易、货物及技术进出口",
                 keywords: "人才招聘,深圳市维电斯科技有限公司",
               },
               component: () => import("../views/job/index.vue"),
             },
             {
               path: "/news",
               name: "news",
               meta: {
                 title: "新闻资讯-深圳市维电斯科技有限公司",
                 icon: "",
                 enTitle: "News Information",
                 description:
                   "维电斯为您提供最新、最全的元器件行业资讯，电子元器件新闻，使您轻松了解电子元器件市场动态，及时了解电子行业资讯能更好的抓住商机，适时把握行业发展脉搏",
                 keywords:
                   "维电斯行业资讯,电子元器件库存新闻,电子元器件市场动态,电子元器件商机",
               },
               component: () => import("../views/news/index.vue"),
             },
             {
               path: "/news_detail",
               name: "news_detail",
               meta: {
                 title: "资讯详情-深圳市维电斯科技有限公司",
                 icon: "",
                 enTitle: "News Information",
                 description:
                   "维电斯为您提供最新、最全的元器件行业资讯，电子元器件新闻，使您轻松了解电子元器件市场动态，及时了解电子行业资讯能更好的抓住商机，适时把握行业发展脉搏",
                 keywords:
                   "维电斯行业资讯,电子元器件库存新闻,电子元器件市场动态,电子元器件商机",
               },
               component: () => import("../views/news/details.vue"),
             },
             {
               path: "/product",
               name: "product",
               meta: {
                 title: "产品中心-深圳市维电斯科技有限公司",
                 icon: "",
                 enTitle: "product center",
                 description:
                   "深圳市维电斯科技有限公司主要经营范围是电子元器件的销售,五金产品,电子产品销售,电子软件开发,国内贸易、货物及技术进出口",
                 keywords:
                   "保险丝保险管,放电管防雷管,压敏电阻,热敏电阻,集成电路,电感,电阻,电容,静电保护器",
               },
               component: () => import("../views/product/index.vue"),
             },
             {
               path: "/application",
               name: "application",
               meta: {
                 title: "产品应用-深圳市维电斯科技有限公司",
                 icon: "",
                 enTitle: "application center",
                 description:
                   "深圳市维电斯科技有限公司主要经营范围是电子元器件的销售,五金产品,电子产品销售,电子软件开发,国内贸易、货物及技术进出口",
                 keywords:
                   "保险丝保险管,放电管防雷管,压敏电阻,热敏电阻,集成电路,电感,电阻,电容,静电保护器",
               },
               component: () => import("../views/application/index.vue"),
             },
           ],
         },
       ];
/**
 * 重写路由的push方法
 */
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
export default new Router({
  mode: "history",
  routes: constantRouterMap,
});
