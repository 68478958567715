import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store.js";
import {
  Input,
  Pagination,
  Menu,
  Submenu,
  MenuItem,
  Carousel,
  CarouselItem,
  Tooltip
} from "element-ui";
import 'element-ui/lib/theme-chalk/index.css'
import VueI18n from 'vue-i18n'
import 'lib-flexible/flexible'
Vue.use(VueI18n)

// 使用多文件管理不同的语言是一个好习惯：
store.commit("SET_LANGUAGE", 'zh');
const i18n = new VueI18n({
  locale:sessionStorage.getItem("languageSet")||'zh',  // 语言标识，后面会用做切换和将用户习惯存储到本地浏览器
  // locale: 'zh', // 语言标识
  messages: {
    'zh': require('./utils/lang/zh'),
    'en': require('./utils/lang/en')
  }
})

import './styles/common.scss'
import './styles/element-ui.scss'
import moment from 'moment' //时间格式化


router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title.keywords.description */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.description) {
        document
          .getElementsByTagName("meta")
          .description.setAttribute("content", to.meta.description);
  }
   if (to.meta.keywords) {
     document
       .getElementsByTagName("meta")
       .keywords.setAttribute("content", to.meta.keywords);
   }
  next();
});
Vue.config.productionTip = false;
Vue.use(Input);
Vue.use(Pagination);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Carousel)
Vue.use(CarouselItem);
Vue.use(Tooltip);

Vue.prototype.$moment = moment;
new Vue({
  router,
  store,
  i18n, 
  render: h => h(App)
}).$mount("#app");
